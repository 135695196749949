@import url('https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300;400;500;600;700&display=swap');

$px-font: 'Space Grotesk', sans-serif !default;

// Theme Variable

$px-theme: #1322f0;
$px-theme-dark: #020535;
$px-theme-rgb: 50, 150, 250;
$px-white: #eee;
$px-black: #000;
$px-text: #b6b6b6;
$px-heading: #deffef;
$px-bik: #fcd93c;

// Breakpoints
$px-media-xsm: 567px !default;
$px-media-sm: 767px !default;
$px-media-md: 991px !default;
$px-media-lg: 1200px !default;
