:root {
  --bs-heading-color: var(--px-heading);
  --px-theme: #{$px-theme};
  --px-theme-rgb: #{$px-theme-rgb};
  --px-black: #{$px-black};
  --px-white: #{$px-white};
  --px-text: #{$px-text};
  --px-heading: #{$px-heading};
  --px-bik: #{$px-bik};
  --px-theme-dark: #{$px-theme-dark};

}
